@import 'variables';

$prefix: $antPrefix + -modal;
$drawerPrefix: $antPrefix + -drawer;
$modalTypes: '&-confirm, &-info, &-success, &-error, &-warning';

.#{$prefix},
.#{$drawerPrefix} {
  color: $textColor;

  &-wrap.no-padding,
  &.no-padding {
    .#{$prefix} {
      &-body {
        padding: 0 !important;
      }
      &-content {
        overflow: hidden;
      }
    }
  }

  &-content {
    background-color: $componentBackground;
  }
  &-close {
    color: $textColorSecondary;
    &:hover {
      color: $textColor;
    }
  }
  &-title {
    color: $textColor;
  }
  &-header,
  &-footer {
    color: $textColor;
    background: transparent;
    border-color: $borderColor;
  }

  #{$modalTypes} {
    &-body .#{$prefix} {
      #{$modalTypes} {
        &-title,
        &-content {
          color: $textColor;
        }
      }
    }
  }
  &-confirm-info .#{$prefix}-confirm-body > .anticon {
    color: $primaryColor;
  }
}

.split-intervention-modal {

  .intervention-date-select .#{$antPrefix}-form-item-label {
    margin-bottom: 15px;
  }

  .#{$antPrefix}-form-item-label {
    text-align: left;
  }

  .#{$antPrefix}-picker {
    width: 175%;
  }

  .#{$antPrefix}-row-end {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .#{$prefix} {
    width: 85vw !important;
  }
}

@media (max-width: 600px) {
  .#{$prefix} {
    width: 95vw !important;
  }
}