@import 'variables';

$prefix: $antPrefix + -drawer;

@media (max-width: 992px) {
    .#{$prefix}-content-wrapper {
        width: 85vw !important;
    }
}

@media (max-width: 650px) {
    .#{$prefix}-content-wrapper {
        width: 100vw !important;
    }
}