@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom-color: $borderColor;
  }
  &-bordered {
    border-color: $borderColor;
  }
}

.home-card {
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  .#{$prefix}-head {
    background: $itemHoverBackground;
  }
}
