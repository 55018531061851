@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }
}

.#{$prefix}-item-label {
  min-width: fit-content;
  label {
    white-space: normal;
  }
}

.truck-hour-form-item {
  width: fit-content;
  justify-content: center;

  .#{$prefix}-item-control,
  .#{$prefix}-item-control-input,
  .#{$prefix}-item-control-input-content {
    width: fit-content;
  }

  input {
    min-width: fit-content;
  }

  .#{$antPrefix}-picker-suffix {
    margin-left: 10px;
  }
}
.format-form-item {
  flex-direction: column;
  .ant-form-item-label {
    margin-bottom: 10px;
  }
}
