@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
    color: $textColor;
}

a.#{$prefix}, .#{$prefix} a {
    color: $primaryColor;

    &:hover {
        color: #962D89;
    }
}