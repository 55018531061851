@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $borderColor;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-tab.#{$prefix}-tab-active .#{$prefix}-tab-btn, .#{$prefix}-tab-btn:focus, .#{$prefix}-tab-remove:focus, .#{$prefix}-tab-btn:active, .#{$prefix}-tab-remove:active {
  color: $primaryColor;
}

.#{$prefix}-tab.#{$prefix}-tab-active .#{$prefix}-tab-btn {
  font-weight: 700;
}

.#{$prefix}-top > .#{$prefix}-nav::before, .#{$prefix}-bottom > .#{$prefix}-nav::before, .#{$prefix}-top > div > .#{$prefix}-nav::before, .#{$prefix}-bottom > div > .#{$prefix}-nav::before,
.#{$prefix}-card > .#{$prefix}-nav .#{$prefix}-tab, .#{$prefix}-card > div > .#{$prefix}-nav .#{$prefix}-tab {
  border-color: $borderColor;
}