@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $clientColor;
  }
}

.#{$prefix} > .#{$prefix}-content {
  height: calc(100vh - 102px);

  > .#{$prefix}-content {
    min-height: calc(100vh - 202px);
  }
}