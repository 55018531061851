@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor !important;
  line-height: 35px;
  &-borderless {
    background-color: $componentBackground !important;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent !important;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor !important;
  }

  &-content {
    color: $textColor !important;
    background: #fff !important;
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0 !important;
}

.#{$prefix} {
  background: $itemHoverBackground;
}