@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }
}

.#{$prefix}-item-container {
  flex-direction: column;

  .#{$prefix}-item-label {
    text-transform: uppercase;
    color: $primaryColor;
    letter-spacing: 0.1em;
    font-size: 0.9em;
  }

  .#{$prefix}-item-content > .#{$antPrefix}-typography {
    font-size: 1.15em;
  }

  .#{$prefix}-item-content {
    flex-wrap: wrap;
  }
}
.invoice-full-width .#{$prefix}-item-container {
  flex-direction: column;

  .#{$prefix}-item-label {
    text-transform: uppercase;
    color: $primaryColor;
    letter-spacing: 0.1em;
    font-size: 0.9em;
  }

  .#{$prefix}-item-content > .#{$antPrefix}-typography {
    font-size: 1.15em;
  }

  .#{$prefix}-item-content {
    flex-wrap: wrap;
    display: block;
  }
}
